$icon-font-path: '../fonts/';
// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "bower_components/slick-carousel/slick/slick.scss";
// endbower

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700');
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400');

body{
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
}

.clear{
  clear: both;
}

.g-recaptcha{
  @media screen and (max-width: 568px) {
    transform: scale(0.77);
    position: absolute;
    left: -18px;
    top: -13px;
  }
}

#wrapper{
  #top{

    #header{
      margin-top: 20px;
      .langs-wrapper{
        margin-bottom: 20px;
        .langs{
          .lang-block{
            ul{
              padding: 0;
              margin: 0;
              text-align: center;
              border: 1px solid #f78f1e;
              border-radius: 25px;
              li{
                list-style: none;
                display: inline-block;
                text-transform: uppercase;
                margin: -3px;
                &:first-child{
                  a{
                    -webkit-border-top-left-radius: 25px;
                    -webkit-border-bottom-left-radius: 25px;
                    -moz-border-radius-topleft: 25px;
                    -moz-border-radius-bottomleft: 25px;
                    border-top-left-radius: 25px;
                    border-bottom-left-radius: 25px;
                    padding-left: 15px;
                  }                  
                }
                &:last-child{
                  a{
                    -webkit-border-top-right-radius: 25px;
                    -webkit-border-bottom-right-radius: 25px;
                    -moz-border-radius-topright: 25px;
                    -moz-border-radius-bottomright: 25px;
                    border-top-right-radius: 25px;
                    border-bottom-right-radius: 25px;
                    padding-right: 14px;
                    padding-left: 10px;
                  }                  
                }
                a{
                  text-decoration: none;
                  color: #000;
                  padding: 2px 7px;
                }
                .active{
                  background: #f78f1e;
                  color: #fff;
                }
              }
            }
          }
        }
      }
      .top-banner-wrapper{
          .banner-left{
            @media screen and (max-width: 767px) {
              padding: 0;
            }
            .img{
              img{
                object-fit:cover;
                height: 235px;
                width: 100%;
              }
            }
          }
          .banner-right{
            background: #f78f1e;
            .col{
              display: flex;
              align-items: left;
              justify-content: center;
              height: 235px;
              color: #fff;
              flex-direction: column;
              font-size: 16px;
              @media screen and (min-width: 767px)and(max-width: 768px) {
                font-size: 10px;
              }
              .title{
                margin: 20px 0;
                font-size: 18px;
                font-weight: 600;
                @media screen and (min-width: 767px)and(max-width: 768px) {
                  font-size: 14px;
                }
              }
              .tlp{
                
              }
              .email{
                a{
                  color: #fff;
                }
              }
            }
          }
        
      }
      .desktop-menu{
        .menus{
          margin: 25px 0;
          ul{
            padding: 0;
            margin: 0;
            text-align: center;
            li{
              display: inline-block;
              list-style: none;
              font-family: 'Open Sans', sans-serif;
              font-weight: 300;
              font-size: 16px;
              @media screen and (max-width: 991px) {
                font-size: 12px;
              }
              a{
                text-decoration: none;
                color: #f78f1e;
                width: 120px;
                height: 50px;
                border: 1px solid #f78f1e;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                margin: 0 10px;
                &:hover{
                  background: #f78f1e;
                  color: #fff;
                }
                @media screen and (max-width: 991px) {
                  margin: 0 5px;
                  width: 80px;
                }
              }
              .active{
                background: #f78f1e;
                color: #fff;
              }
            }
          }
        }
      }
      .mobile-menu{
        .navbar-default{
          background: transparent;
          border: none;
          .navbar-header{
            background: #fff;
            button{
              border: none;
              padding: 10px 0;
              background: none;
              span{
                background: #f78f1e;
              }
            }
          }
          #navbar{
            background: #fff;
            .langx{
              border-top: 1px solid #ccc; 
              li{
                float: left;
                text-transform: uppercase;
                .active{
                  color: #f78f1e;
                }
              }
            }
          }
        }
      }

      #home-col{
        .home-col-left{
          @media screen and (max-width: 991px) {
            margin-bottom: 20px;
          }
          .blocks{
            color: #fff;
            position: relative;
            .img{
              position: relative;
              overflow: hidden;
              img{
                object-fit:cover;
                height: 575px;
                width: 100%;
                @media screen and (max-width: 568px) {
                  object-position: center right;
                }
              }
              .overlay{
                position: absolute;
                background: rgba(0, 0, 0, 0.3);
                top: 0;
                left: 0;
                right: 0;
                height: 100%;
              }
            }
            .text-column{
              position: absolute;
              bottom: 20px;
              padding: 20px;
              .title{
                text-align: center;
                text-transform: uppercase;
                font-size: 46px;
                font-weight: 600;
                @media screen and (max-width: 991px) {
                  font-size: 25px;
                  line-height: normal;
                }
              }
              .desc{
                font-size: 21px;
                opacity: 0.8;
                text-align: justify;                
                @media screen and (max-width: 991px) {
                  font-size: 20px;
                  line-height: normal;
                }
              }
            }
            
          }
        }
        .home-col-right{
          .blocks{
            a{
              margin-bottom: 15px;
              text-decoration: none;
              position: relative;
              color: #fff;
              display: inline-block;
              width: 100%;
              &:hover{
                .img{
                  .overlay{
                    background: #f78f1e;
                  }
                }
              }
              .img{
                position: relative;
                img{
                  object-fit:cover;
                  height: 128px;
                  @media screen and (max-width: 991px) {
                    object-fit: cover;
                    height: 200px;
                    width: 100%;
                  }
                }
                .overlay{
                  background: rgba(247, 143, 30, 0.8);
                  position: absolute;
                  top: 0;
                  height: 100%;
                  width: 100%;
                  transition: background-color 0.5s ease;
                }
              }
              .text-col{
                position: absolute;
                bottom: 0;
                padding: 10px 5px;
                .title{
                  font-size: 16px;
                  font-weight: 600;
                  text-transform: uppercase;
                }
                .desc{
                  font-size: 12px;
                }
                .title2{
                  font-weight: 600;
                  font-size: 13px;
                }
                .subtitle{
                  font-weight: 600;
                  padding: 10px 0;
                }
                .tlp{
                  font-size: 12px;
                }
                .emails{
                  font-size: 10px;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }

    #content{
      .top-banner-wrapper{
          .banner-left{
            @media screen and (max-width: 767px) {
              padding: 0;
            }
            .img{
              img{
                object-fit:cover;
                height: 235px;
                width: 100%;
              }
            }
          }
          .banner-right{
            background: #f78f1e;
            .col{
              display: flex;
              align-items: left;
              justify-content: center;
              height: 235px;
              color: #fff;
              flex-direction: column;
              font-size: 16px;
              @media screen and (min-width: 767px)and(max-width: 768px) {
                font-size: 10px;
              }
              .title{
                margin: 20px 0;
                font-size: 18px;
                font-weight: 600;
                @media screen and (min-width: 767px)and(max-width: 768px) {
                  font-size: 14px;
                }
              }
              .tlp{
                
              }
              .email{
                a{
                  color: #fff;
                }
              }
            }
          }
        
      }
      .desktop-menu{
        .menus{
          margin: 25px 0;
          ul{
            padding: 0;
            margin: 0;
            text-align: center;
            li{
              display: inline-block;
              list-style: none;
              font-family: 'Open Sans', sans-serif;
              font-weight: 300;
              font-size: 16px;
              @media screen and (max-width: 991px) {
                font-size: 12px;
              }
              a{
                text-decoration: none;
                color: #f78f1e;
                width: 120px;
                height: 50px;
                border: 1px solid #f78f1e;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                margin: 0 10px;
                &:hover{
                  background: #f78f1e;
                  color: #fff;
                }
                @media screen and (max-width: 991px) {
                  margin: 0 5px;
                  width: 80px;
                }
              }
              .active{
                background: #f78f1e;
                color: #fff;
              }
            }
          }
        }
      }
      .mobile-menu{
        .navbar-default{
          background: transparent;
          border: none;
          .navbar-header{
            background: #fff;
            button{
              border: none;
              padding: 10px 0;
              background: none;
              span{
                background: #f78f1e;
              }
            }
          }
          #navbar{
            background: #fff;
            .langx{
              border-top: 1px solid #ccc; 
              li{
                float: left;
                text-transform: uppercase;
                .active{
                  color: #f78f1e;
                }
              }
            }
          }
        }
      }
      .column-page{
        .desc-column{
          margin: 8% 0 20px;
          .col-logo{
            @media screen and (max-width: 568px) {
              margin-bottom: 50px;
            }
            .logo{
              img{}
            }
          }
          .col-txt{
            .title{
              font-size: 36px;
              color: #f67f1c;
              font-weight: 600;
              text-transform: uppercase;
              display: inline-block;
              border-bottom: 2px solid;
              margin-bottom: 10px;
              line-height: 30px;
            }
            .desc{
              font-size: 18px;
              line-height: 38px;
            }
          }
        }        
      }
      .column-maps{
        border-top: 3px solid #f78f1e;
        border-bottom: 3px solid #f78f1e;
        .google-maps {
              position: relative;
              padding-bottom: 40%; // This is the aspect ratio
              height: 0;
              overflow: hidden;
          }
          .google-maps iframe {
              position: absolute;
              top: 0;
              left: 0;
              width: 100% !important;
              height: 100% !important;
          }
      }
      .column-content{
        background: #f4f5f5;
        padding: 20px 0;
        .pages{
          .breadcumb{
            color: #f78f1e;
            a{
              color: #f78f1e;
            }
          }
          .content-pages{
            padding: 40px 0;
            .content-left{
              .content-img{
                border: 2px solid #f78f1e;
                @media screen and (max-width: 991px) {
                  margin-bottom: 20px;
                }
                img{
                  object-fit:cover;
                  height: 378px;
                  width: 100%;
                  @media screen and (max-width: 568px) {
                    object-position: right;
                  }
                }
              }
              .content-slide{
                .slider-for{
                  margin-bottom: 20px;
                  .img{
                    object-fit:cover;
                    height: 305px;
                    width: 100%;
                  }
                }
                .slider-nav{
                   /* the slides */
                  .slick-slide {
                    margin: 0 8px;
                  }
                  /* the parent */
                  .slick-list {
                    margin: 0 -8px;
                  }
                  .slick-current {
                    .overlay{
                      opacity: 1 !important;
                    }
                  }
                  .img{
                    position: relative;
                    img{
                      object-fit:cover;
                      height: 133px;
                      width: 100%;
                    }
                    .overlay{
                      background: rgba(247, 143, 30, 0.8);
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      height: 100%;
                      opacity: 0;
                      transition: background-color 0.5s ease;
                    }
                  }
                  .prev{
                    position: absolute;
                    top: 40%;
                    left: -10px;
                    z-index: 1;
                    cursor: pointer;
                  }
                  .next{
                    position: absolute;
                    top: 40%;
                    right: -10px;
                    cursor: pointer;
                  }
                }
              }
            }
            .content-right{
              .title{
                font-weight: 600;
                text-transform: uppercase;
                color: #f78f1e;
                font-size: 22px;
                margin-bottom: 20px;
              }
              .desc{
                font-size: 18px;
                line-height: 30px;
              }
              .contact-form{
                form{
                  label{
                    font-size: 18px;
                  }
                  input{
                    width: 100%;
                    border: 1px solid #f78f1e;
                    background: #eceeee;
                  }
                  select{
                    width: 100%;
                    border: 1px solid #f78f1e;
                    background: #eceeee;
                  }
                  textarea{
                    width: 100%;
                    border: 1px solid #f78f1e;
                    background: #eceeee;
                  }
                  .capcay{
                    border: 1px solid #f78f1e;
                    display: inline-block;
                  }
                  .submit{
                    text-align: right;
                    button{
                      background: #f78f1e;
                      text-transform: uppercase;
                      color: #fff;
                      border: none;
                      border-radius: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .column-product{
        padding: 50px 0;
        background: #f4f5f5;
        .seemore{
          text-align: center;
          margin: 40px 0 25px;
          a{
            display: inline-block;
            text-decoration: none;
            text-align: center;
            color: #f78f1e;
            font-size: 18px;
            text-transform: capitalize;
            .img{
              display: inline-block;
              width: 100%;
              img{
                display: inline-block;
              }
            }
          }
        }
        .blocks{
          .details{
            display: block;
            position: relative;
            margin-bottom: 30px;
            .img{
              position: relative;
              img{
                object-fit:cover;
                height: 255px;
                width: 100%;
              }
            }
          }
        }
      }
      .home-bg{
        position: relative;
        margin: 40px 0;
        background: #e3e5e5;
        min-height: 100%;
        padding: 10% 0;
        overflow: hidden;
        .gear{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          text-align: center;
          img{
            display: inline-block;
          }
        }
        .home-column{
          display: flex;
          justify-content: left;
          align-items: center;
          height: 670px;
          @media screen and (max-width: 767px) {
            flex-direction:column;
            height: 100%;
          }
          @media screen and (min-width: 768px)and(max-width: 991px) {
            height: auto;
          }
          .col-logo{
            @media screen and (max-width: 568px) {
              margin-bottom: 20px;
            }
            .logo{
              @media screen and (max-width: 768px) {
                margin: 20px 0;  
              }
            }
          }
          .title{
            font-size: 36px;
            color: #f67f1c;
            text-transform: uppercase;
            display: inline-block;
            border-bottom: 2px solid;
            margin-bottom: 10px;
            line-height: 30px;
            font-weight: 600;
          }
          .desc{
            color: #555555;
            font-size: 18px;
            line-height: 30px;
            @media screen and (max-width: 991px) {
              font-size: 14px;
              line-height: normal;  
            }
          }
        }
      }
    }

    #footer{
      .footer-columns{
       margin: 10% 0 5%;
        .footer-col{
          @media screen and (max-width: 767px) {
            margin-bottom: 40px;
          }
          .title{
            text-transform: uppercase;
            color: #f78f1e;
            font-family: 'Roboto', sans-serif;
            font-size: 22px;
            margin-bottom: 20px;
          }
          .menu{
            ul{
              padding: 0;
              margin: 0;
              li{
                list-style: none;
                font-size: 18px;
                margin-bottom: 5px;
                a{
                  text-decoration: none;
                  color: #333;
                  &:hover{
                    color: #f78f1e;
                  }
                }
              }
            }
          }
          .sosmed-list{
            @media screen and (min-width: 1200px) {
              width: 50%;
            }
            ul{
              padding: 0;
              margin: 0;
              text-align: left;
              li{
                list-style: none;
                display: inline-block;
                font-size: 20px;
                margin-right: 20px;
                a{
                  display: block;
                  text-decoration: none;
                  color: #333;
                  &:hover{
                    color: #f78f1e;
                  }
                }
              }
            }
          }
          .column{
            .blocks{
              margin-bottom: 10px;
              .ico{
                float: left;
                margin-right: 7px;
                margin-top: 3px;
              }
              .txt{
                font-size: 18px;
                a{
                  text-decoration: none;
                  color: #333;
                  &:hover{
                    color: #f78f1e;
                  }
                }
              }
            }
          }
        }
      }
      .footer-copy{
        background: #f78f1e;
        color: #fff;
        text-align: center;
        padding: 40px 0;
        a{
          color: #fff;
          text-decoration: none;
          &:hover{
            color: #333;
          }
        }
      }
    }
  }
}